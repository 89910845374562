export default function () {
  return {
    config: {
      defaultWhiteLabel: {
        loginPrompt: "",
        companyLogo: null,
        companyName: null,
        termsOfService: null,
        settings: null,
      },
      whiteLabel: {
        loginPrompt: "",
        companyLogo: null,
        companyName: null,
        termsOfService: null,
        settings: null,
      },
      providers: [
        {
          url: "/auth/login/local",
          icon: "/nb-logo.png",
          text: "Development Login",
          local: true,
        },
      ],
    },
  };
}
