const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/LoginWindow.vue"),
        meta: { public: true },
      },
    ],
  },
  {
    path: "/login2fa",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/Login2FAWindow.vue"),
        meta: { public: true },
      },
    ],
  },
  {
    path: "/login2fasetup",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/Login2FASetupWindow.vue"),
        meta: { public: true },
      },
    ],
  },
  {
    path: "/forgotpw",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/ForgotPWWindow.vue"),
        meta: { public: true },
      },
    ],
  },
  {
    path: "/changepw",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/ChangePWWindow.vue"),
        meta: { public: true },
      },
    ],
  },
  {
    path: "/forgotemailsent",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/ForgotEmailSentWindow.vue"),
        meta: { public: true },
      },
    ],
  },
  {
    path: "/resetpassword",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/ResetPasswordWindow.vue"),
        meta: { public: true },
      },
    ],
  },
  {
    path: "/register",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/RegisterWindow.vue"),
        meta: { public: true },
      },
    ],
  },
  {
    path: "/alreadyexists",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/AlreadyRegisteredWindow.vue"),
        meta: { public: true },
      },
    ],
  },
  {
    path: "/registrationcomplete",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/RegistrationEmailSentWindow.vue"),
        meta: { public: true },
      },
    ],
  },
  {
    path: "/confirmregistration",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/RegistrationConfirmedWindow.vue"),
        meta: { public: true },
      },
    ],
  },
  {
    path: "/registrationfailed",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/RegistrationConfirmationError.vue"),
        meta: { public: true },
      },
    ],
  },
  {
    path: "/passwordreseterror",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/PasswordResetError.vue"),
        meta: { public: true },
      },
    ],
  },
  {
    path: "/policyupdate",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/PolicyUpdateAgreement.vue"),
        meta: { public: true },
      },
    ],
  },
  {
    path: "/accountdeleted",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/AccountDeleted.vue"),
        meta: { public: true },
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:pathMatch(.*)*",
    redirect: "/login",
  },
];

export default routes;
