import { store } from "quasar/wrappers";
import { createStore } from "vuex";

import countries from "@portals/quasar-app-extension-utils-quasar/src/store/countries";
import localisations from "@portals/quasar-app-extension-utils-quasar/src/store/localisations";
import companies from "./companies";

//Vue.use(Vuex);

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */
export default store(function (/* { ssrContext } */) {
  const Store = createStore({
    modules: {
      companies,
      countries,
      localisations,
    },

    // enable strict mode (adds overhead!)
    // for dev mode and --debug builds only
    strict: process.env.DEBUGGING,
  });

  return Store;
});
