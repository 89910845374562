export function config(state) {
  return state.config;
}

export function whiteLabel(state) {
  return state.config.whiteLabel;
}

export function loginPrompt(state, getters) {
  return getters.config.whiteLabel.loginPrompt
    ? getters.config.whiteLabel.loginPrompt
    : "";
}

export function companySubDomain(state, getters) {
  return getters.config.whiteLabel.subDomain
    ? getters.config.whiteLabel.subDomain
    : null;
}
export function companyTermsTitle(state, getters) {
  if (getters.config.whiteLabel.companyName) {
    return getters.config.whiteLabel.companyName + " Terms of Service";
  }
  return "";
}
export function companyTermsContent(state, getters) {
  if (getters.config.whiteLabel.termsOfService) {
    // Check it is not just empty HTML tags....this happens if user just deletes
    // what they can see in the editor.
    let cleanText = getters.config.whiteLabel.termsOfService.replace(
      /<\/?[^>]+(>|$)/g,
      ""
    );
    if (cleanText) {
      return getters.config.whiteLabel.termsOfService;
    }
  }
  return "";
}
export function companyLogo(state, getters) {
  if (getters.config.whiteLabel.companyLogo) {
    return "/auth/companyLogo?logoId=" + getters.config.whiteLabel.companyLogo;
  }
  return null;
}
export function localLoginDisabled(state, getters) {
  if (
    getters.config.whiteLabel.settings &&
    getters.config.whiteLabel.settings.ssoproviders &&
    getters.config.whiteLabel.settings.ssoproviders.localLoginDisabled
  ) {
    return true;
  }
  return false;
}
export function microsoftSSOEnabled(state, getters) {
  if (
    getters.config.whiteLabel.settings &&
    getters.config.whiteLabel.settings.ssoproviders &&
    getters.config.whiteLabel.settings.ssoproviders.microsoft &&
    getters.config.whiteLabel.settings.ssoproviders.microsoft.enabled
  ) {
    return true;
  }
  return false;
}
export function pingSSOEnabled(state, getters) {
  if (
    getters.config.whiteLabel.settings &&
    getters.config.whiteLabel.settings.ssoproviders &&
    getters.config.whiteLabel.settings.ssoproviders.ping &&
    getters.config.whiteLabel.settings.ssoproviders.ping.enabled
  ) {
    return true;
  }
  return false;
}
export function oidcSSOEnabled(state, getters) {
  if (
    getters.config.whiteLabel.settings &&
    getters.config.whiteLabel.settings.ssoproviders &&
    getters.config.whiteLabel.settings.ssoproviders.oidc &&
    getters.config.whiteLabel.settings.ssoproviders.oidc.enabled
  ) {
    return true;
  }
  return false;
}
export function googleSSOEnabled(state, getters) {
  if (
    getters.config.whiteLabel.settings &&
    getters.config.whiteLabel.settings.ssoproviders &&
    getters.config.whiteLabel.settings.ssoproviders.google &&
    getters.config.whiteLabel.settings.ssoproviders.google.enabled
  ) {
    return true;
  }
  return false;
}
