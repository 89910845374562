import axios from "axios";

export default class WhiteLabelController {
  constructor() {}

  async getWhiteLabelInfo(serviceUrl) {
    let response = await axios.get("/auth/whitelabel?serviceURL=" + serviceUrl);
    if (response) {
      return response.data;
    }
  }
}
